import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Tooltip, message } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../css/GlobalCmp.module.css";
import "./TiposActividad.scss";

// Picker Color
import ColorPicker from "rc-color-picker";
// Styles Color Picker
import "rc-color-picker/assets/index.css";

// Icon Picker
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
// Styles Icon Picker
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
// Objetos de Iconos FontAwesome 4.7
import * as iconDefs from "../../../utils/awsIcon";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import { Height } from "@material-ui/icons";
import { updateEnviromentAction } from "../../../actions/enviromentActions";

export default function TiposAactividadForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.tac_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [colorFondo, setColorFondo] = useState(record.tac_color);
  const [icono, setIcono] = useState(record.tac_icono);

  useEffect(() => {
    setCurId(record.tac_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  // Props del Icon Picker
  const propsiconpicker = {
    icons: iconDefs.fontAwesome,
    theme: "bluegrey",
    renderUsing: "class",
    value: record.tac_icono,
    onChange: (value) => setIcono(value),
    isMulti: false,
    searchPlaceholder: "Buscar Iconos",
    noIconPlaceholder: "Icono no encontrado",
    noSelectedPlaceholder: "Seleccione Icono",
    allCatPlaceholder: "Mostrar Todos",
    iconsPerPage: 40,
  };

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendTipoAct(
      currentUser,
      curId,
      colorFondo,
      icono,
      "TipoActividad",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE TIPOS DE ACTIVIDAD",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE TIPOS DE ACTIVIDAD",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="tipoactividad"
      onFinish={guardarCambios}
      initialValues={{
        tac_nombre: record.tac_id > 0 ? record.tac_nombre : "",
        tac_color: record.tac_id > 0 ? record.tac_color : "",
        tac_icono: record.tac_id > 0 ? record.tac_icono.toLowerCase() : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.id > 0
            ? "ACTUALIZAR TIPO DE ACTIVIDAD"
            : "AGREGAR TIPO DE ACTIVIDAD"}
        </Title>
      </Form.Item>
      <Form.Item
        name="tac_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.tac_id > 0 ? record.tac_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="tac_color"
        label="Color"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal">
          <Input value={colorFondo} style={{ width: 100 }} />
          <ColorPicker
            animation="slide-up"
            color={record.tac_id > 0 ? record.tac_color : ""}
            onChange={(color) => setColorFondo(color.color)}
          />
        </Space>
      </Form.Item>
      <Form.Item name="tac_icono" label="Icono">
        <Space direction="horizontal">
          <Input value={icono} style={{ width: 200 }} />
          <FontIconPicker {...propsiconpicker} />
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
