import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import CRM from "../../../../../utils/CrmService";

export default function TrmMotivosRetiroForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.mtv_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [libera, setLibera] = useState(record.mtv_libera);
  const [aplica, setAplica] = useState(record.mtv_aplica);

  useEffect(() => {
    setCurId(record.mtv_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await CRM.sendTrmMotivosRetiro(
      currentUser,
      curId,
      libera,
      "TrmMotivosRetiro",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE MOTIVOS DE RETIRO",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE MOTIVOS DE RETIRO",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="TrmMotivosRetiro"
      onFinish={guardarCambios}
      initialValues={{
        mtv_nombre: record.mtv_id > 0 ? record.mtv_nombre : "",
        mtv_aplica: record.mtv_id > 0 ? record.mtv_aplica : "",
        mtv_libera: record.mtv_id > 0 ? record.mtv_libera : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.mtv_id > 0
            ? "ACTUALIZAR MOTIVOS DE RETIRO"
            : "AGREGAR MOTIVOS DE RETIRO"}
        </Title>
      </Form.Item>
      <Form.Item
        name="mtv_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.mtv_id > 0 ? record.mtv_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="mtv_aplica"
        label="Aplica en.."
        rules={[
          {
            required: true,
            message: "El aplica es obligatorio",
          },
        ]}
      >
        <Radio.Group
          onChange={(option) => setAplica(option.target.value)}
          value={aplica}
        >
          <Radio value={1}>Ventas</Radio>
          <Radio value={2}>Tramites</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="mtv_libera"
        label="Libera Cliente"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={10}>
        <Switch
            checked={(libera === 1)?1:0}
            onChange={(val) => setLibera(val ? 1 : 2)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
