import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Table, Input, Button, Space, message } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { TitlePage } from "../../../../TitlePage";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import newRec from "../../../../../assets/icons/file-edit-outline.svg";
import backHome from "../../../../../assets/icons/home-outline.svg";

//Importacion del form
import InvMarcasForm from "./InvMarcasForm";

//Importaciones propias
import ModalApp from "../../../../Modal";
import { colorPrm } from "../../../../../constants";

/**
 * Cuentas Bancarias la Lista que está seteada en el State
 */
export default function InvMarcas() {
  const stkmarcas = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.stkmarcas
  );
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [record, setRecord] = useState(null);
  const history = useHistory();

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => history.push(`/${currentUser.pagina}`),
      src: backHome,
      style: { color: "red" },
    },
    {
      onClick: () => openModal(null),
      src: newRec,
    },
  ];
  //Fin floating
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "mrc_id",
      key: "mrc_id",
      width: "5%",
      ...getColumnSearchProps("mrc_id"),
    },
    {
        title: "Nombre",
        dataIndex: "mrc_nombre",
        key: "mrc_nombre",
        width: "90%",
        ...getColumnSearchProps("mrc_nombre"),
      },
    {
      title: "+",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          onClick={() => openModal(record)}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  // MODALES/////////////////////
  const openModal = (item) => {
    if (item) {
      setRecord(item);
    } else {
      let newRecord = {
        mrc_id: 0,
      };
      setRecord(newRecord);
    }

    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

  const probar = () => {
    message.success("controlado", 1);
  };

  return (
    <div style={{ margin: 15 }}>
      <TitlePage title="ABM Marcas" />
      <Table
        columns={columns}
        dataSource={stkmarcas}
        pagination={{ pageSize: 45 }}
        scroll={{ y: window.innerHeight - 230 }}
      />
      <FloatingButtons
        buttonType="plus"
        buttonColor={"#ffffff"}
        backgroundColor={colorPrm}
        dimension={60}
        buttonsList={buttonsList}
        top={"calc(85% - 25px)"}
        left={"calc(90% - 25px)"}
        direction="circular"
        degree={-180}
        distance={100}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={600}
      >
        <InvMarcasForm
          record={record}
          closeModal={closeModal}
          currentUser={currentUser}
          varEffec={Math.random()}
        />
      </ModalApp>
    </div>
  );
}