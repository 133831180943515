import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Table, Input, Button, Space, message } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { TitlePage } from "../../../../TitlePage";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import newRec from "../../../../../assets/icons/file-edit-outline.svg";
import backHome from "../../../../../assets/icons/home-outline.svg";

//Importacion del form
import PrvProveedoresForm from "./PrvProveedoresForm";

//Importaciones propias
import ModalApp from "../../../../Modal";
import { colorPrm } from "../../../../../constants";
import { showNotification } from "../../../../../utils/ShowNotification";
import ERP from "../../../../../utils/ErpService";


/**
 * Cuentas Bancarias la Lista que está seteada en el State
 */
export default function PrvProveedores(props) {
  const { varEffec } = props;
  const monedas = useSelector(
    (state) => state.enviroment.enviroment.monedas
  );
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [record, setRecord] = useState(null);
  const [listado, setListado] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      let datos = await ERP.getTerceros(currentUser,'2');
      if (datos.type && datos.type > 0) {
        setListado(datos.listado);
      } else {
        showNotification(
          "topRight",
          2,
          "ERROR DE CONEXION",
          "Intente mas Tarde"
        );
      }
    })();
  }, [varEffec]);

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => history.push(`/${currentUser.pagina}`),
      src: backHome,
      style: { color: "red" },
    },
    {
      onClick: () => openModal(null),
      src: newRec,
    },
  ];
  //Fin floating
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "trc_id",
      key: "trc_id",
      width: "10%",
      ...getColumnSearchProps("trc_id"),
    },
    {
      title: "Codigo",
      dataIndex: "trc_codigo",
      key: "trc_codigo",
      width: "7%",
      ...getColumnSearchProps("trc_codigo"),
    },
    {
        title: "Nombre",
        dataIndex: "trc_nombre",
        key: "trc_nombre",
        width: "20%",
        ...getColumnSearchProps("trc_nombre"),
      },
      {
        title: "Contacto",
        dataIndex: "trc_contacto",
        key: "trc_contacto",
        width: "10%",
        ...getColumnSearchProps("trc_contacto"),
      },
      {
        title: "Ced.Jur.",
        dataIndex: "trc_documento",
        key: "trc_documento",
        width: "10%",
        ...getColumnSearchProps("trc_documento"),
      },
      {
        title: "Telefono",
        dataIndex: "trc_telefono",
        key: "trc_telefono",
        width: "10%",
        ...getColumnSearchProps("trc_telefono"),
      },
      {
        title: "Ciudad",
        dataIndex: "ciu_nombre",
        key: "ciu_nombre",
        width: "13%",
        ...getColumnSearchProps("ciu_nombre"),

      },
      {
        title: "Moneda",
        dataIndex: "cue_moneda",
        key: "cue_moneda",
        width: "7%",
        render: (text, record) => (
            record.cue_moneda === 1 ? monedas.moneda : monedas.moneda_frn
          ),
      },
      {
        title: "Activo",
        dataIndex: "trc_activo",
        key: "trc_activo",
        width: "8%",
        render: (text, record) => (record.trc_activo)?<i class="fa fa-check-circle-o" aria-hidden="true"></i>:null,
      },
      {
      title: "+",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          onClick={() => openModal(record)}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  // MODALES/////////////////////
  const openModal = (item) => {
    if (item) {
      setRecord(item);
    } else {
      let newRecord = {
        grp_id: 0,
      };
      setRecord(newRecord);
    }

    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

  const probar = () => {
    message.success("controlado", 1);
  };

  return (
    <div style={{ margin: 15 }}>
      <TitlePage title="ABM Proveedores" />
      <Table
        columns={columns}
        dataSource={listado}
        pagination={{ pageSize: 45 }}
        scroll={{ y: window.innerHeight - 230 }}
      />
      <FloatingButtons
        buttonType="plus"
        buttonColor={"#ffffff"}
        backgroundColor={colorPrm}
        dimension={60}
        buttonsList={buttonsList}
        top={"calc(85% - 25px)"}
        left={"calc(90% - 25px)"}
        direction="circular"
        degree={-180}
        distance={100}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={600}
      >
        <PrvProveedoresForm
          record={record}
          closeModal={closeModal}
          currentUser={currentUser}
          varEffec={Math.random()}
        />
      </ModalApp>
    </div>
  );
}