import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Select, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

export default function CategoriasForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.id);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setCurId(record.id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const { Title } = Typography;

  const { Option } = Select;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendMaestro(
      currentUser,
      curId,
      "Categorias",
      values
    );
    console.log(val_record);
    if (val_record.type && Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE CATEGORIAS DE ANIMALES",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE CATEGORIAS DE ANIMALES",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="categorias"
      onFinish={guardarCambios}
      initialValues={{
        ctg_nombre: record.id > 0 ? record.ctg_nombre : "",
        ctg_minimo: record.id > 0 ? record.ctg_minimo : "",
        ctg_maximo: record.id > 0 ? record.ctg_maximo : "",
        ctg_auto: record.id > 0 ? record.ctg_auto : 0,
        ctg_sexo: record.ctg_sexo,
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.id > 0 ? "ACTUALIZAR CATEGORIA" : "AGREGAR CATEGORIA"}
        </Title>
      </Form.Item>
      <Form.Item
        name="ctg_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.id > 0 ? record.ctg_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="ctg_minimo"
        label="Edad Mínima"
        rules={[
          {
            required: true,
            message: "El minimo es obligatorio",
          },
        ]}
      >
        <Input
          style={{ width: 50, textTransform: "uppercase" }}
          maxLength={3}
          value={record.id > 0 ? record.ctg_minimo : ""}
        />
      </Form.Item>
      <Form.Item
        name="ctg_maximo"
        label="Edad Máxima"
        rules={[
          {
            required: true,
            message: "El maximo es obligatorio",
          },
        ]}
      >
        <Input
          style={{ width: 50, textTransform: "uppercase" }}
          maxLength={3}
          value={record.id > 0 ? record.ctg_maximo : ""}
        />
      </Form.Item>
      <Form.Item name="ctg_auto" label="Automatico">
        <Switch
          checkedChildren="SI"
          unCheckedChildren="NO"
          defaultChecked={record.ctg_auto}
        />
      </Form.Item>
      <Form.Item
        name="ctg_sexo"
        label="Sexo"
        rules={[
          {
            required: true,
            message: "El sexo es obligatorio",
          },
        ]}
      >
        <Select defaultValue={record.ctg_sexo} style={{ width: 120 }}>
          <Option key={2} value={2}>
            Hembra
          </Option>
          <Option key={1} value={1}>
            Macho
          </Option>
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
