import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch, Select } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import ERP from "../../../../../utils/ErpService";

export default function InvSubgruposArtForm(props) {
  const { record, closeModal, currentUser, stkgrupos, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.sbg_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [grupo, setGrupo] = useState(!record.sbg_id ? "" : record.grp_id);

  const { Option } = Select;

  useEffect(() => {
    setCurId(record.sbg_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await ERP.sendInvSubgruposArt(
      currentUser,
      curId,
      grupo,
      "InvSubgruposArt",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE SUBGRUPOS DE ARTICULOS",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE SUBGRUPOS DE ARTICULOS",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="InvSubgruposArt"
      onFinish={guardarCambios}
      initialValues={{
        sbg_nombre: record.sbg_id > 0 ? record.sbg_nombre : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.sbg_id > 0
            ? "ACTUALIZAR SUBGRUPOS DE ARTICULOS"
            : "AGREGAR SUBGRUPOS DE ARTICULOS"}
        </Title>
      </Form.Item>
      <Form.Item
        name="grp_id"
        label="Grupo"
        wrapperCol={{ span: 100 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
          <Select
            showSearch
            showArrow
            style={{ width: "200px" }}
            defaultValue={grupo}
            onChange={(val) => setGrupo(val)}
            placeholder="Grupo"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {stkgrupos.map((grupos) => (
              <Option key={grupos.grp_id} value={grupos.grp_id}>
                {grupos.grp_nombre}
              </Option>
            ))}
          </Select>
      </Form.Item>
      <Form.Item
        name="sbg_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.sbg_id > 0 ? record.sbg_nombre : ""}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
