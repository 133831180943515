import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Divider,
  Space,
} from "antd";
import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./DetalleVacuna.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function DetalleVacuna(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    opcionCarga,
    detalleList,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleCarga]);

  const { Title } = Typography;

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const deleteCambio = async () => {
    setLoadingButton(true);
    if (Number.parseInt(detalleCarga.cmb_estado) > 0) {
      showNotification(
        "topRight",
        2,
        "ELIMINAR CAMBIO DE LOTE",
        "EL CAMBIO DE LOTE YA FUE CONFIRMADO"
      );
    } else {
      let record = {
        ent_id: detalleCarga.ent_numero,
        ent_option: opcionCarga,
      };
      var val_inventory = await FRM.updateCambio(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.ganaderia);
        showNotification(
          "topRight",
          4,
          "ELIMINAR CAMBIO",
          "MOVIMIENTO ELIMINADO CON EXITO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.anm_peso_neto);
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        DETALLE DESPARASITADO
      </Title>
      <Row gutter={8}>
        <Col span={10}>
          <Statistic
            title="Fecha"
            value={detalleCarga.vcn_fecha}
            prefix={<CalendarOutlined />}
          />
        </Col>
        <Col span={14}>
          <Statistic title="Vacuna Aplicada" value={detalleCarga.vcn_nombre} />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={12}>
          <Statistic
            title="Ubicacion de Aniumales"
            value={detalleCarga.cmp_nombre}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Dosis Aplicadas"
            value={myNumber(1, detalleCarga.vcn_animales)}
            valueStyle={{ color: colorSec }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Importe Cancelado"
            value={myNumber(1, detalleCarga.vcn_importe)}
            valueStyle={{ color: colorPrm }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={dataDef}
        bordered
        size="middle"
        scroll={{ y: 640 }}
      />
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Statistic
            value={
              detalleCarga.vcn_nota.length > 0 ? detalleCarga.vcn_nota : ""
            }
          />
        </Col>
      </Row>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => deleteCambio()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display:
                dataDef.length > 0 &&
                detalleCarga.cmb_estado === 0 &&
                opcionCarga === 3
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <DeleteOutlined /> DESCARTAR EL CAMBIO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
