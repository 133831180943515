import React from "react";
import { useSelector } from "react-redux";

//Componentes adicionales
import { AALLayout } from "../../components/AALLayout";
import { TitlePage } from "../../components/TitlePage";

//Erp
import InvAranceles from "../../components/Erp/Maestros/Inventarios/InvAranceles";
import InvArticulos from "../../components/Erp/Maestros/Inventarios/InvArticulos";/*
import InvArticulosSpw from "../../components/Erp/Maestros/Inventarios/InvArticulosSpw";*/
import InvBodegas from "../../components/Erp/Maestros/Inventarios/InvBodegas";
import InvComposiciones from "../../components/Erp/Maestros/Inventarios/InvComposiciones";
import InvCompradores from "../../components/Erp/Maestros/Inventarios/InvCompradores";
import InvGrupoArticulo from "../../components/Erp/Maestros/Inventarios/InvGrupoArticulo";
import InvMarcas from "../../components/Erp/Maestros/Inventarios/InvMarcas";
import InvSubgruposArt from "../../components/Erp/Maestros/Inventarios/InvSubgruposArt";
import InvTejidos from "../../components/Erp/Maestros/Inventarios/InvTejidos";
import InvUnidades from "../../components/Erp/Maestros/Inventarios/InvUnidades";

// Comercial

import VntCiudades from "../../components/Erp/Maestros/Comercial/VntCiudades";
import VntClientes from "../../components/Erp/Maestros/Comercial/VntClientes";/*
import VntClientesSp from "../../components/Erp/Maestros/Comercial/VntClientesSp";
import VntConceptosNotas from "../../components/Erp/Maestros/Comercial/VntConceptosNotas";*/
import VntFormasPago from "../../components/Erp/Maestros/Comercial/VntFormasPago";
import VntRegiones from "../../components/Erp/Maestros/Comercial/VntRegiones";
import VntVendedores from "../../components/Erp/Maestros/Comercial/VntVendedores"

// Proveedores

import PrvCiudades from "../../components/Erp/Maestros/Proveedores/PrvCiudades";
import PrvConceptosNotas from "../../components/Erp/Maestros/Proveedores/PrvConceptosNotas";
import PrvProveedores from "../../components/Erp/Maestros/Proveedores/PrvProveedores";
import PrvRegiones from "../../components/Erp/Maestros/Proveedores/PrvRegiones";

// Cajas y Bancos

import CjbCajasCuentas from "../../components/Erp/Maestros/Finanzas/CjbCajasCuentas";
import CjbConceptos from "../../components/Erp/Maestros/Finanzas/CjbConceptos";

//Configuracion
//import { Opciones } from "../../components/Maestros/Configuracion/Opciones";
//import { TipoUsuarios } from "../../components/Maestros/Configuracion/TipoUsuarios";

/**
 * MaestrosPage para cargar los componentes validando el currentComponent del State
 */
export default function MaestrosErp() {
  const currentComponent = useSelector(
    (state) => state.currentComponent.currentComponent
  );

  function loadComponent(currentComponent) {
    switch (currentComponent.opcion) {
      case "InvAranceles":
        return <InvAranceles />;
        case "InvArticulos":
          return <InvArticulos varEffec={Math.random()} />;
        case "InvBodegas":
          return <InvBodegas />;
        case "InvComposiciones":
          return <InvComposiciones />;
        case "InvCompradores":
          return <InvCompradores />;
        case "InvGrupoArticulo":
          return <InvGrupoArticulo />;
        case "InvMarcas":
          return <InvMarcas />;
        case "InvSubgruposArt":
          return <InvSubgruposArt />;
        case "InvTejidos":
          return <InvTejidos />;
        case "InvUnidades":
          return <InvUnidades />;
        case "PrvCiudades":
          return <PrvCiudades />;
        case "PrvConceptosNotas":
          return <PrvConceptosNotas />;
          case "PrvProveedores":
            return <PrvProveedores varEffec={Math.random()} />;
          case "PrvRegiones":
          return <PrvRegiones />;
        case "CjbCajasCuentas":
          return <CjbCajasCuentas />;
        case "CjbConceptos":
          return <CjbConceptos />;

        case "VntVendedores":
          return <VntVendedores />;
        case "VntRegiones":
          return <VntRegiones />;
        case "VntCiudades":
          return <VntCiudades />;
        case "VntClientes":
          return <VntClientes varEffec={Math.random()} />;
        case "VntFormasPago":
          return <VntFormasPago />;
      default:
        return <h2>No hay maestro definido</h2>;
    }
  }

  return (
    <AALLayout>
      <TitlePage title="ABM Maestros" />
      {loadComponent(currentComponent)}
    </AALLayout>
  );
}
