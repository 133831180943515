import { showNotification } from "./ShowNotification";
import { URL_SERVICE } from "../constants";

class SRV {
  // Datos Usuarios
  getUser(user) {
    let body = new FormData();
    body.append("id_mobile", user);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Update Password Usuarios
  updatePassword(user) {
    console.log("aca esta header " + URL_SERVICE);
    let body = new FormData();
    body.append("id_mobile", user);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Datos de entorno
  getEnviroment(dbName, id_mobile, wservice) {
    let body = new FormData();
    body.append("dbName", dbName);
    body.append("id_mobile", id_mobile);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/5`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Ticket
  sendTicket(dbName, id_mobile, ticket) {
    let uploadData = new FormData();
    uploadData.append("dbName", dbName);
    uploadData.append("id_mobile", id_mobile);
    uploadData.append("ticket", JSON.stringify(ticket));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Tickets
  sendGestion(dbName, id_mobile, packDatos) {
    let uploadData = new FormData();
    uploadData.append("dbName", dbName);
    uploadData.append("id_mobile", id_mobile);
    uploadData.append("packDatos", JSON.stringify(packDatos));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/4`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Tickets
  updateCodBar(dbName, id_mobile, art_id, art_codbar) {
    let uploadData = new FormData();
    uploadData.append("dbName", dbName);
    uploadData.append("id_mobile", id_mobile);
    uploadData.append("art_id", art_id);
    uploadData.append("art_codbar", art_codbar);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/3`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Movimientos de Efectivo
  sendEfectivo(dbName, id_mobile, packDatos) {
    let uploadData = new FormData();
    uploadData.append("dbName", dbName);
    uploadData.append("id_mobile", id_mobile);
    uploadData.append("packDatos", JSON.stringify(packDatos));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/6`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Elimar gestion, Ticket o Eefectivo
  deleteGestion(dbName, id_mobile, tipo, idGestion) {
    let uploadData = new FormData();
    uploadData.append("dbName", dbName);
    uploadData.append("id_mobile", id_mobile);
    uploadData.append("tipo", tipo);
    uploadData.append("idGestion", idGestion);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_SERVICE}/7`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }
}

export default new SRV();
