import React, { useState } from "react";
import { Table, Input, Button, Space, message } from "antd";
import Highlighter from "react-highlight-words";

import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CreateLot from "../CreateLot";
import DetalleLote from "../DetalleLote";
import EditLote from "../EditLote";
import CrearGasto from "../CrearGasto";
import { colorPrm } from "../../../constants";
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import CST from "../../../utils/CustomSettings";

//Estilos
import "./CambiosLote.scss";

export default function CambiosLote(props) {
  const { setIsLoading, myNumber } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleCarga, setDetalleCarga] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [detalleList, setDetalleList] = useState([]);
  const [cuentasEfe, setCuentasEfe] = useState([]);
  const [cuentasBnc, setCuentasBnc] = useState([]);
  const [cuentasChk, setCuentasChk] = useState([]);
  const [listaCnc, setListaCnc] = useState([]);

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const listEntradas = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cambios_lote
  );

  const entradasDet = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.entradas_det
  );

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleCarga(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const getDetalle = async (record, opcion) => {
    if (opcion === 4) {
      openModal(record, opcion, "900px");
    } else {
      let datos = await FRM.getLoteDet(currentUser, record.cmb_numero);
      if (datos.type && datos.type > 0) {
        setDetalleList(datos.listado);
        openModal(record, opcion, "900px");
      } else {
        showNotification(
          "topRight",
          2,
          "ERROR DE CONEXION",
          "Intente mas Tarde"
        );
      }
    }
  };

  const addGasto = async (record, opcion) => {
    let objeto = {
      idMov: record.cmb_numero,
      tipoMov: 1,
      idOpt: 1,
    };
    let datos = await FRM.getGastosMov(currentUser, objeto);
    console.log(datos);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      setListaCnc(datos.listacnc);
      setCuentasEfe(datos.listaefe);
      setCuentasBnc(datos.listabnc);
      setCuentasChk(datos.listachk);
      openModal(record, opcion, "900px");
    } else {
      showNotification("topRight", 2, "ERROR DE CONEXION", "Intente mas Tarde");
    }
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDark}
          size="large"
          shape="circle"
          onClick={() => getDetalle(record, 2)}
        >
          {record.cmb_numero}
        </Button>
      ),
    },
    {
      title: "FECHA",
      dataIndex: "cmb_fecha",
      width: "8%",
      key: "cmb_fecha",
      ...getColumnSearchProps("cmb_fecha"),
    },
    {
      title: "DIAS",
      dataIndex: "cmb_dias",
      width: "5%",
      key: "cmb_dias",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_estado > 0 ? row.cmb_dias : row.dias);
      },
    },
    {
      title: "PROCEDENCIA",
      dataIndex: "cmp_origen",
      key: "cmp_origen",
      ...getColumnSearchProps("cmp_origen"),
      render: (text, row, index) => {
        if (row.org_id === 0) {
          return "NUEVA COMPRA";
        } else {
          return row.cmp_origen;
        }
      },
    },
    {
      title: "CAMPO DESTINO",
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
      ...getColumnSearchProps("cmp_nombre"),
    },
    {
      title: "CANTD",
      dataIndex: "cmb_cantidad",
      key: "cmb_cantidad",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_cantidad);
      },
    },
    {
      title: "CONTEO",
      dataIndex: "cmb_animales",
      key: "cmb_animales",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_animales);
      },
    },
    {
      title: "PRECIO",
      dataIndex: "cmb_lote",
      key: "cmb_lote",
      width: "8%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_lote ? row.cmb_lote : 0);
      },
    },
    {
      title: "TRANSPORTE",
      dataIndex: "cmb_transporte",
      key: "cmb_transporte",
      width: "8%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.trc_transporte > 0 ? row.cmb_transporte : 0.0);
      },
    },
    {
      title: "+ GASTOS",
      dataIndex: "cmb_otros",
      key: "cmb_otros",
      width: "8%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_otros > 0 ? row.cmb_otros : 0.0);
      },
    },
    {
      title: "ESTADO",
      dataIndex: "cmb_estado",
      key: "cmb_estado",
      width: "8%",
      align: "left",
      render: (text, row, index) => {
        if (row.cmb_estado === 0) {
          return "VIGENTE";
        } else {
          return "CERRADA";
        }
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnWarning}
          size="large"
          shape="circle"
          onClick={() =>
            record.cmb_estado === 0
              ? addGasto(record, 5)
              : message.error("EL CAMBIO YA ESTA CERRADO", 1)
          }
        >
          {"$"}
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
          icon={<EditOutlined />}
          onClick={() =>
            record.cmb_estado === 0
              ? getDetalle(record, 4)
              : message.error("EL CAMBIO YA ESTA CERRADO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          danger
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() =>
            record.cmb_estado === 0
              ? getDetalle(record, 3)
              : message.error(
                  "EL CAMBIO YA ESTA CONFIRMADO... NO SE PUEDE ELIMINAR",
                  1
                )
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listEntradas}
        style={{ padding: 3, marginTop: 5 }}
      />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.darkBdy,
          position: "absolute",
          right: 150,
          bottom: 100,
        }}
        onClick={() => openModal(null, 1, "900px")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CreateLot
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
          />
        ) : tipoModal === 2 ? (
          <DetalleLote
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={2}
            detalleList={detalleList}
          />
        ) : tipoModal === 3 ? (
          <DetalleLote
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={3}
            detalleList={detalleList}
          />
        ) : tipoModal === 4 ? (
          <EditLote
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={3}
          />
        ) : (
          <CrearGasto
            currentUser={currentUser}
            closeModal={closeModal}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            detalleList={detalleList}
            listaCnc={listaCnc}
            cuentasEfe={cuentasEfe}
            cuentasBnc={cuentasBnc}
            cuentasChk={cuentasChk}
            opcionCarga={1}
            tituloModal={"AGREGAR GASTO A CAMBIO DE LOTE"}
            id_mov={detalleCarga.cmb_numero}
            numero_anm={detalleCarga.cmb_cantidad}
          />
        )}
      </ModalApp>
    </div>
  );
}
