import React, { useState } from "react";
import {
  Button,
  Table,
  Typography,
  Select,
  Row,
  Col,
  Statistic,
  Divider,
  Popconfirm,
} from "antd";
import { CalendarOutlined, SaveOutlined } from "@ant-design/icons";
import Icon from "@mdi/react";
import { mdiCow } from "@mdi/js";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import "./EditLote.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import CST from "../../../utils/CustomSettings";

export default function EditEntrada(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    opcionCarga,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const updateEntrada = async () => {
    setLoadingButton(true);
    if (Number.parseInt(detalleCarga.ent_estado) > 0) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "EL PROCESO YA FUE FINALIZADO"
      );
    } else {
      let record = {
        cmb_id: detalleCarga.cmb_numero,
        usr_id: detalleCarga.usr_id,
        cmb_lote: Number.parseFloat(
          ((detalleCarga.cmb_area * detalleCarga.cmp_alquiler) / 30) *
            detalleCarga.dias
        ),
        numero: detalleCarga.cmb_animales,
        cmb_option: opcionCarga,
      };
      console.log(record);
      var val_inventory = await FRM.confirmLote(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "FINALIZAR LOTE",
          "PROCESO REGISTRADO CON EXITO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "FINALIZAR LOTE",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"FINALIZAR ESTADIA EN LOTE"}
      </Title>
      <Row gutter={8}>
        <Col span={6}>
          <Statistic
            title="Fecha"
            value={detalleCarga.cmb_fecha}
            prefix={<CalendarOutlined />}
          />
        </Col>
        <Col span={9}>
          <Statistic
            title="Campo de Origen"
            value={
              detalleCarga.org_id === 0
                ? "ENTRADA NUEVA"
                : detalleCarga.cmp_origen
            }
          />
        </Col>
        <Col span={9}>
          <Statistic
            title="Campo de Destino"
            value={
              detalleCarga.cmp_nombre +
              " (" +
              myNumber(1, detalleCarga.cmb_area) +
              "Has)"
            }
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={2}>
          <Icon path={mdiCow} size={"35px"} color={CST.lilaBdy} />
        </Col>
        <Col span={12}>
          <Statistic
            value={
              "Animales en origen: " + myNumber(1, detalleCarga.cmb_cantidad)
            }
            valueStyle={{ color: CST.lilaBdy }}
          />
        </Col>
        <Col span={2}>
          <Icon path={mdiCow} size={"35px"} color={CST.greenBdy} />
        </Col>
        <Col span={8}>
          <Statistic
            value={
              "Animales contados: " + myNumber(1, detalleCarga.cmb_animales)
            }
            valueStyle={{ color: CST.greenBdy, textAlign: "right" }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={12}>
          <Statistic
            value={
              "Gastos registrados: $" + myNumber(1, detalleCarga.cmb_otros)
            }
            valueStyle={{ color: CST.redBdy }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            value={
              "Valor por uso del lote: $" +
              myNumber(
                1,
                ((detalleCarga.cmb_area * detalleCarga.cmp_alquiler) / 30) *
                  detalleCarga.dias
              )
            }
            valueStyle={{ color: CST.blueFoo, textAlign: "right" }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Statistic
            title="Observaciones:"
            value={detalleCarga.cmb_nota}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => updateEntrada()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnOrange}
            shape="round"
            block
            loading={loadingButton}
          >
            <SaveOutlined /> REGISTRAR FINALIZACION
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
