import React, { useState, useEffect } from "react";
import {
  Form,
  Typography,
  Input,
  Space,
  Button,
  Radio,
  Switch,
  message,
} from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import ERP from "../../../../../utils/ErpService";

export default function InvGrupoArticuloForm(props) {
  const { record, closeModal, currentUser, stkgrupos, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.grp_id);
  const [grpCode, setGrpCode] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setCurId(record.grp_id);
    setGrpCode(record.grp_id > 0 ? record.grp_codigo : "");
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const onChangeGrupo = (e) => {
    const codigoGrp = e.target.value;
    if (validarGrp(stkgrupos, codigoGrp)) {
      setGrpCode(codigoGrp.toUpperCase());
    } else {
      message.error("EL CODIGO DE GRUPO YA EXISTE", 1);
      return false;
    }
  };

  //Funcion para validar la existencia del grupo
  const validarGrp = async (stkgrupos, codigoGrp) => {
    stkgrupos.forEach((arrayValue) => {
      if (arrayValue.grp_codigo === codigoGrp) {
        return false;
      }
    });
  };

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await ERP.sendMaestro(
      currentUser,
      curId,
      "InvGrupoArticulo",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE GRUPO DE ARTICULOS",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE GRUPO DE ARTICULOS",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="InvGrupoArticulo"
      onFinish={guardarCambios}
      initialValues={{
        grp_nombre: record.grp_id > 0 ? record.grp_nombre : "",
        grp_codigo: record.grp_id > 0 ? record.grp_codigo : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.grp_id > 0
            ? "ACTUALIZAR GRUPO DE ARTICULOS"
            : "AGREGAR GRUPO DE ARTICULOS"}
        </Title>
      </Form.Item>
      <Form.Item
        name="grp_codigo"
        label="Codigo"
        rules={[
          {
            required: true,
            message: "El codigo es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase", width: "100px" }}
          value={grpCode}
          onChange={onChangeGrupo}
        />
      </Form.Item>
      <Form.Item
        name="grp_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.grp_id > 0 ? record.grp_nombre : ""}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
