import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

// Picker Color
import ColorPicker from "rc-color-picker";

// Icon Picker
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
// Styles Icon Picker
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
// Objetos de Iconos FontAwesome 4.7
import * as iconDefs from "../../../../../utils/awsIcon";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import CRM from "../../../../../utils/CrmService";

export default function VntTipoActividadForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.tac_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [aplica, setAplica] = useState(record.tac_id > 0 ? record.tac_aplica : 0);
  const [respuesta, setRespuesta] = useState(record.tac_id > 0 ? record.tac_respuesta === 1?1: 0 : 0);
  const [motivo, setMotivo] = useState(record.tac_id > 0 ? record.tac_motivo === 1?0: 1 : 0);
  const [auto, setAuto] = useState(record.tac_id > 0 ? record.tac_especial === 1?1: 0 : 0);
  const [agenda, setAgenda] = useState(record.tac_id > 0 ? record.tac_cierra === 1?1: 0 : 0);
  const [colorFondo, setColorFondo] = useState(record.tac_color);
  const [colorFuente, setColorFuente] = useState(record.tac_fuente);
  const [icono, setIcono] = useState(record.tac_icono);


  useEffect(() => {
    setCurId(record.tac_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  // Props del Icon Picker
  const propsiconpicker = {
    icons: iconDefs.fontAwesome,
    theme: "bluegrey",
    renderUsing: "class",
    value: record.tac_icono,
    onChange: (value) => setIcono(value),
    isMulti: false,
    searchPlaceholder: "Buscar Iconos",
    noIconPlaceholder: "Icono no encontrado",
    noSelectedPlaceholder: "Seleccione Icono",
    allCatPlaceholder: "Mostrar Todos",
    iconsPerPage: 40,
  };

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await CRM.sendVntTipoActividad(
      currentUser,
      curId,
      respuesta,
      motivo,
      auto,
      agenda,
      colorFondo,
      colorFuente,
      icono,
      "VntTipoActividad",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE TIPOS DE ACTIVIDAD",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE TIPOS DE ACTIVIDAD",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="VntTipoActividad"
      onFinish={guardarCambios}
      initialValues={{
        tac_nombre: record.tac_id > 0 ? record.tac_nombre : "",
        tac_aplica: record.tac_id > 0 ? record.tac_aplica : "",
        tac_dias: record.tac_id > 0 ? record.tac_dias : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.tac_id > 0
            ? "ACTUALIZAR TIPOS DE ACTIVIDAD"
            : "AGREGAR TIPOS DE ACTIVIDAD"}
        </Title>
      </Form.Item>
      <Form.Item
        name="tac_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.tac_id > 0 ? record.tac_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="tac_aplica"
        label="Aplica en..."
        rules={[
          {
            required: true,
            message: "El tipo es obligatorio",
          },
        ]}
      >
        <Radio.Group
          onChange={(option) => setAplica(option.target.value)}
          value={aplica}
        >
          <Radio value={1}>Ventas y Carteras</Radio>
          <Radio value={2}>Tramites</Radio>
          <Radio value={3}>Ambos</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="tac_respuesta"
        label="Responder"
        wrapperCol={{ span: 15 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={46}>
        <Switch
            checked={respuesta}
            onChange={(val) => setRespuesta(val === 1? 1 : 2)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />

          Pide Motivo:
          <Switch
            checked={motivo}
            onChange={(val) => setMotivo(val === 1? 1 : 2)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item
        name="tac_dias"
        label="Dias"
        rules={[
          {
            required: true,
            message: "Los dias es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase", width: "80px" }}
          value={record.tac_id > 0 ? record.tac_dias : ""}
        />
      </Form.Item>
      <Form.Item
        name="tac_auto"
        label="Áutomatica"
        wrapperCol={{ span: 15 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={33}>
        <Switch
            checked={auto}
            onChange={(val) => setAuto(val === 1? 1 : 2)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
          
          Afecta Agenda:
          <Switch
            checked={agenda}
            onChange={(val) => setAgenda(val === 1? 1 : 2)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
          
        </Space>
      </Form.Item>
      <Form.Item
        name="tac_color"
        label="Color Fondo"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal">
          <Input value={colorFondo} style={{ width: 100 }} />
          <ColorPicker
            animation="slide-up"
            color={record.tac_id > 0 ? record.tac_color : ""}
            onChange={(color) => setColorFondo(color.color)}
          />
        </Space>
      </Form.Item>
      <Form.Item name="tac_icono" label="Icono">
        <Space direction="horizontal">
          <Input value={icono} style={{ width: 200 }} />
          <FontIconPicker {...propsiconpicker} />
        </Space>
      </Form.Item>
      <Form.Item
        name="tac_color"
        label="Color Fuente"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal">
          <Input value={colorFuente} style={{ width: 100 }} />
          <ColorPicker
            animation="slide-up"
            color={record.tac_id > 0 ? record.tac_fuente : ""}
            onChange={(color) => setColorFuente(color.color)}
          />
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
