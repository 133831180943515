import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";

import { SettingOutlined } from "@ant-design/icons";

//currentComponent Action
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentComponentAction } from "../actions/currentComponentActions";

const LeftMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    userModules,
    userOptions,
    gstOpciones,
    currentUser,
    closeDrawer,
  } = props;
  console.log(userModules);
  const Pagina = currentUser.pagina;
  const { SubMenu } = Menu;
  const updateCurrentComponent = (state) =>
    dispatch(updateCurrentComponentAction(state));

  function handleClick(e, opcion) {
    e.preventDefault();
    let curComp = {
      opcion: opcion.parametros,
      titulo: opcion.opcion,
    };
    updateCurrentComponent(curComp);
    closeDrawer();
    history.push(`/${opcion.url}`);
  }

  return (
    <div>
      <Menu
        style={{ width: "100%" }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
      >
        {userModules.map((modulo) => (
          <SubMenu
            key={modulo.modulo_id}
            icon={<SettingOutlined />}
            title={modulo.modulo}
          >
            {gstOpciones.map((gst_opcion) =>
              gst_opcion.modulo_id === modulo.modulo_id &&
              gst_opcion.parent === 0 ? (
                <SubMenu
                  key={gst_opcion.id}
                  icon={<SettingOutlined />}
                  title={gst_opcion.nombre}
                >
                  {userOptions.map((submenu) =>
                    submenu.modulo_id === gst_opcion.modulo_id &&
                    submenu.parent === gst_opcion.id ? (
                      <Menu.Item key={submenu.opcion_id}>
                        <Link
                          key={submenu.opcion_id}
                          to={"/" + submenu.url}
                          className="link"
                          onClick={(e) => handleClick(e, submenu)}
                        >
                          {submenu.opcion}
                        </Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )
                  )}
                </SubMenu>
              ) : (
                ""
              )
            )}
          </SubMenu>
        ))}
      </Menu>
    </div>
  );
};

export default LeftMenu;
