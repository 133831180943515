import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, DatePicker, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

import moment from 'moment';

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import ERP from "../../../../../utils/ErpService";

export default function VntFormasPagoForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.fpg_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [fecha, setFecha] = useState(record.fpg_fecha ? record.fpg_fecha : moment().format('YYYY-MM-DD'));
  const [control, setControl] = useState(record.fpg_id > 0 ? record.fpg_control : 0);
  const [guardarFecha, setGuardarFecha] = useState(record.fpg_control == 1? fecha : null);

  useEffect(() => {
    setCurId(record.fpg_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await ERP.sendVntFormasPago(
      currentUser,
      curId,
      control,
      fecha,
      "VntFormasPago",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE FORMAS DE PAGO",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE FORMAS DE PAGO",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="VntFormasPago"
      onFinish={guardarCambios}
      initialValues={{
        fpg_nombre: record.fpg_id > 0 ? record.fpg_nombre : "",
        fpg_plazo: record.fpg_id > 0 ? record.fpg_plazo : "",
        fpg_mostrar: record.fpg_id > 0 ? record.fpg_mostrar : "",
        fpg_fecha: record.fpg_id > 0 ? record.fpg_fecha : 0,
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.fpg_id > 0
            ? "ACTUALIZAR FORMAS DE PAGO"
            : "AGREGAR FORMAS DE PAGO"}
        </Title>
      </Form.Item>
      <Form.Item
        name="fpg_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.fpg_id > 0 ? record.fpg_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="fpg_plazo"
        label="Plazo"
      >
        <Input
          style={{ textTransform: "uppercase", width: "100px" }}
          value={record.fpg_id > 0 ? record.fpg_plazo : ""}
        />
      </Form.Item>
      <Form.Item
        name="fpg_mostrar"
        label="Mostrar"
      >
        <Input
          style={{ textTransform: "uppercase", width: "100px" }}
          value={record.fpg_id > 0 ? record.fpg_mostrar : ""}
        />
      </Form.Item>
      <Form.Item
        name="fpg_control"
        label="Aplica Fecha"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal">
        <Switch
            checked={control}
            onChange={(val) => setControl(val ? 1 : null)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item
        name="fpg_fecha"
        label="Fecha"
        wrapperCol={{ span: 10 }}>
        <DatePicker 
          onChange={setFecha} 
          placeholder="Seleccione Fecha" 
          defaultValue={moment(fecha, 'YYYY-MM-DD')}
          format={'DD-MM-YYYY'}
          style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
