import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Typography, Input, Button, message } from "antd";

//Firebase
import firebase from "../../utils/Firebase";
import "firebase/auth";
import { reauthenticate } from "../../utils/Api";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateUserAction } from "../../actions/userActions";
import { updateLoguedAction } from "../../actions/isLoguedActions";

import { LockOutlined } from "@material-ui/icons";

//Styles
import curStyle from "../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../utils/CustomSettings";
import { showNotification } from "../../utils/ShowNotification";
import SRV from "../../utils/Service";

export default function CahngePass(props) {
  const { closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repitePassword, setRepitePassword] = useState("");

  //Ejecutar dispatch SEGUN BECESIDADES
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateUserAction(state));
  const updateLogued = (state) => dispatch(updateLoguedAction(state));

  const history = useHistory();

  const layout = {
    wrapperCol: { span: 24 },
  };

  const { Title } = Typography;

  const onChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangeRepite = (e) => {
    setRepitePassword(e.target.value);
  };

  const guardarCambios = async (values) => {
    if (!oldPassword || !password || !repitePassword) {
      showNotification(
        "topRight",
        2,
        "ACTUALIZAR PASSWORD",
        "Todos los campos son obligatorios!"
      );
    } else {
      if (password.length < 7) {
        showNotification(
          "topRight",
          2,
          "ACTUALIZAR PASSWORD",
          "La contraseña DEBE TENER MINIMO 8 CARACTERES"
        );
      } else {
        if (password !== repitePassword) {
          showNotification(
            "topRight",
            2,
            "CONTRASEÑAS DIFERENTES",
            "Las contraseñas no son iguales!"
          );
        } else {
          if (password === oldPassword) {
            showNotification(
              "topRight",
              2,
              "CONTRASEÑAS DIFERENTES",
              "Las nueva contraseña es igual a la anterior!"
            );
          } else {
            setLoadingButton(true);
            try {
              await firebase
                .auth()
                .signInWithEmailAndPassword(currentUser.email, oldPassword)
                .then(() => {
                  firebase
                    .auth()
                    .currentUser.updatePassword(password)
                    .then(() => {
                      setLoadingButton(false);
                      message.success(
                        "Password actualizado correcatmente... REINICIE SESION",
                        2
                      );
                      closeModal();
                    })
                    .catch(() => {
                      message.error("Error al actualizar el password", 2);
                      setLoadingButton(false);
                    });
                })
                .catch((error) => {
                  setLoadingButton(false);
                  showNotification(
                    "topRight",
                    2,
                    "ACCESO DENEGADO",
                    error.message
                  );
                });
            } catch (error) {
              setLoadingButton(false);
              showNotification(
                "topRight",
                2,
                "CONTROL DE ACCESO",
                "ERROR AL INTENTAR LA CONEXION... INTENTE MAS TARDE"
              );
            }
          }
        }
      }
    }
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="categorias"
      onFinish={guardarCambios}
      initialValues={{
        current_pass: "",
        new_password: "",
        pass_repeat: "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "center",
            color: CST.colorSec,
          }}
        >
          {"ACTUALIZAR PASSWORD"}
        </Title>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: CST.blueBdy }} />}
          placeholder="Password Actual"
          onChange={onChangeOldPassword}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: CST.greenBdy }} />}
          placeholder="Nuevo Password"
          onChange={onChangePassword}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please repita el password!",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: CST.greenBdy }} />}
          placeholder="Repetir Nuevo Password"
          onChange={onChangeRepite}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className={curStyle.btnInfo}
          block
          size="large"
          shape="round"
          htmlType="button"
          onClick={guardarCambios}
          loading={loadingButton}
        >
          ACTUALIZAR EL PASSOWRD
        </Button>
      </Form.Item>
    </Form>
  );
}
