import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch, Select } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import ERP from "../../../../../utils/ErpService";

export default function CjbConceptosForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.cnc_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [CtaId, setCtaId] = useState(record.cnc_id > 0 ? record.cta_id == 1? 1 : 2 : 0);
  const [cncTipo, setCncTipo] = useState(record.cnc_tipo);
  const [cncClase, setCncClase] = useState(record.cnc_id > 0 ? record.cnc_clase : 0);

  useEffect(() => {
    setCurId(record.cnc_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 },
  };

  const { Option } = Select;
  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await ERP.sendCjbConceptos(
      currentUser,
      curId,
      CtaId,
      cncTipo,
      cncClase,
      "CjbConceptos",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE CONCEPTOS",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE CONCEPTOS",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="CjbConceptos"
      onFinish={guardarCambios}
      initialValues={{
        cnc_nombre: record.cnc_id > 0 ? record.cnc_nombre : "",
        cnc_tipo: record.cnc_id > 0 ? record.cnc_tipo : "",
        cta_id: record.cnc_id > 0 ? record.cta_id : "",
        cnc_clase: record.cnc_id > 0 ? record.cnc_clase : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.cnc_id > 0
            ? "ACTUALIZAR CONCEPTOS"
            : "AGREGAR CONCEPTOS"}
        </Title>
      </Form.Item>
      <Form.Item
        name="cnc_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.cnc_id > 0 ? record.cnc_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="cnc_tipo"
        label="Tipo"
        rules={[
          {
            required: true,
            message: "El tipo es obligatorio",
          },
        ]}
      >
        <Radio.Group
          onChange={(option) => setCncTipo(option.target.value)}
          value={cncTipo}
        >
          <Radio value={1}>Ingresos</Radio>
          <Radio value={2}>Gastos</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="cnc_clase"
        label="Clase"
        wrapperCol={{ span: 100 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
          <Select
            showSearch
            showArrow
            style={{ width: "200px" }}
            defaultValue={cncClase}
            onChange={(val) => setCncClase(val)}
            placeholder="Clase"
          >
              <Option key={0} value={0}>
                Elija la clase...
              </Option>
              <Option key={5} value={5}>
                CXC ACCIONISTAS
              </Option>
              <Option key={4} value={4}>
                GASTOS DE INVERSION
              </Option>
              <Option key={1} value={1}>
                GASTOS FIJOS
              </Option>
              <Option key={3} value={3}>
                GASTOS RECUPERABLES
              </Option>
              <Option key={2} value={2}>
                GASTOS VARIABLES
              </Option>
              <Option key={6} value={6}>
                OTROS INGRESOS
              </Option>
          </Select>
      </Form.Item>
      <Form.Item
        name="cta_id"
        label="Afecta Utilidad"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={10}>
        <Switch
            checked={CtaId == 1 ? 1 : 0}
            onChange={(val) => setCtaId(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
