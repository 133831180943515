import { showNotification } from "./ShowNotification";
const URL_USER = "https://api.aal-estate.com/crm/api";

class CRM {
  //Datos de entorno
  getEnviroment(user) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros
  sendMaestro(user, id, maestro, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros Tipo Actividad
  sendTipoAct(user, id, fuente, icono, maestro, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("fuente", fuente);
    uploadData.append("icono", icono);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros Caja y Cuentas
  sendCjbCuentas(user, id, moneda, agrupa, maestro, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("moneda", moneda);
    uploadData.append("agrupa", agrupa);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros Caja y Cuentas
  sendCjbConceptos(user, id, itbm, tipo, maestro, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("itbm", itbm);
    uploadData.append("tipo", tipo);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }
  
    // Envio de Maestros Estado Cliente
    sendVntEstadoCliente(user, id, colorfondo, bloquea, maestro, record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("colorfondo", colorfondo);
      uploadData.append("bloquea", bloquea);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Medios Publicidad
    sendVntMediosPubl(user, id, colorfondo, general, web, presupuesto, activo, maestro, record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("colorfondo", colorfondo);
      uploadData.append("general", general);
      uploadData.append("web", web);
      uploadData.append("presupuesto", presupuesto);
      uploadData.append("activo", activo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Tipo Actividad
    sendVntTipoActividad(
      user, 
      id, 
      respuesta,
      motivo,
      auto,
      agenda,
      colorfondo,
      colorfuente,
      icono, 
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("respuesta", respuesta);
      uploadData.append("motivo", motivo);
      uploadData.append("auto", auto);
      uploadData.append("agenda", agenda);
      uploadData.append("colorfondo", colorfondo);
      uploadData.append("colorfuente", colorfuente);
      uploadData.append("icono", icono);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Nivel de Interes
    sendVntNivelInteres(user, id, color, maestro, record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("color", color);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Nivel de Proyecto
    sendVntNivelProyecto(user, id, tramite, maestro, record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("tramite", tramite);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Estado Inmueble
    sendVntEstadoInmueble(
      user, 
      id,       
      bloquea,
      factura,
      precio,
      comision,
      colorfondo, 
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("bloquea", bloquea);
      uploadData.append("factura", factura);
      uploadData.append("precio", precio);
      uploadData.append("comision", comision);
      uploadData.append("color", colorfondo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Vendedores
    sendVntVendedores(
      user, 
      id,
      compartecliente,
      activo,
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("compartecliente", compartecliente);
      uploadData.append("activo", activo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Tramitadores
    sendTrmTramitadores(
      user, 
      id,
      activo,
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("activo", activo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Gestores de Cartera
    sendCjbGestoresCartera(
      user, 
      id,
      activo,
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("activo", activo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Cajas y cuentas bancarias
    sendCjbCajasCuentas(
      user, 
      id,
      activa,
      tipo,
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("activa", activa);
      uploadData.append("tipo", tipo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Motivos de Retiro
    sendTrmMotivosRetiro(
      user, 
      id,
      libera,
      maestro, 
      record) {
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("libera", libera);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

}

export default new CRM();
