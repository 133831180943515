import React from "react";
import { useSelector } from "react-redux";

//Componentes adicionales
import { AALLayout } from "../../components/AALLayout";
import { TitlePage } from "../../components/TitlePage";

//Crm
import VntClaseInmueble from "../../components/Crm/Maestros/Comercial/VntClaseInmueble";
import VntEstadoCliente from "../../components/Crm/Maestros/Comercial/VntEstadoCliente";
import VntEstadoInmueble from "../../components/Crm/Maestros/Comercial/VntEstadoInmueble";
import VntMediosPubl from "../../components/Crm/Maestros/Comercial/VntMediosPubl";
import VntMotivoCompra from "../../components/Crm/Maestros/Comercial/VntMotivoCompra";
import VntNivelInteres from "../../components/Crm/Maestros/Comercial/VntNivelInteres";
import VntNivelProyecto from "../../components/Crm/Maestros/Comercial/VntNivelProyecto";
import VntRequisitos from "../../components/Crm/Maestros/Comercial/VntRequisitos";
import VntSociedades from "../../components/Crm/Maestros/Comercial/VntSociedades";
import VntTipoActividad from "../../components/Crm/Maestros/Comercial/VntTipoActividad";
import VntTipoAdjunto from "../../components/Crm/Maestros/Comercial/VntTipoAdjunto";
import VntTipoCliente from "../../components/Crm/Maestros/Comercial/VntTipoCliente";
import VntTipoConstruccion from "../../components/Crm/Maestros/Comercial/VntTipoConstruccion";
import VntTipoDocumento from "../../components/Crm/Maestros/Comercial/VntTipoDocumento";
import VntTipoInmueble from "../../components/Crm/Maestros/Comercial/VntTipoInmueble";
import VntTipoPromo from "../../components/Crm/Maestros/Comercial/VntTipoPromo";
import VntTipoProyecto from "../../components/Crm/Maestros/Comercial/VntTipoProyecto";
import VntUbicaciones from "../../components/Crm/Maestros/Comercial/VntUbicaciones";
import VntVendedores from "../../components/Crm/Maestros/Comercial/VntVendedores";

import CjbCajasCuentas from "../../components/Crm/Maestros/Finanzas/CjbCajasCuentas";
import CjbConceptos from "../../components/Crm/Maestros/Finanzas/CjbConceptos";
import CjbGestoresCartera from "../../components/Crm/Maestros/Finanzas/CjbGestoresCartera";

import TrmBancoHipotecario from "../../components/Crm/Maestros/Tramites/TrmBancoHipotecario";
import TrmBancoInterino from "../../components/Crm/Maestros/Tramites/TrmBancoInterino";
import TrmMotivosRetiro from "../../components/Crm/Maestros/Tramites/TrmMotivosRetiro";/*
import TrmTipoAdenda from "../../components/Crm/Maestros/Tramites/TrmTipoAdenda";*/
import TrmTramitadores from "../../components/Crm/Maestros/Tramites/TrmTramitadores";



/**
 * MaestrosPage para cargar los componentes validando el currentComponent del State
 */
export default function MaestrosFrm() {
  const currentComponent = useSelector(
    (state) => state.currentComponent.currentComponent
  );

  function loadComponent(currentComponent) {
    switch (currentComponent.opcion) {
      case "VntClaseInmueble":
        return <VntClaseInmueble />;
        case "VntEstadoCliente":
          return <VntEstadoCliente />;
        case "VntEstadoInmueble":
          return <VntEstadoInmueble />;
        case "VntMediosPubl":
          return <VntMediosPubl />;
        case "VntMotivoCompra":
          return <VntMotivoCompra />;
        case "VntNivelInteres":
          return <VntNivelInteres />;
        case "VntNivelProyecto":
          return <VntNivelProyecto />;
        case "VntRequisitos":
          return <VntRequisitos />;
        case "VntSociedades":
          return <VntSociedades />;
        case "VntTipoActividad":
          return <VntTipoActividad />;
        case "VntTipoAdjunto":
          return <VntTipoAdjunto />;
        case "VntTipoCliente":
          return <VntTipoCliente />;
        case "VntTipoConstruccion":
          return <VntTipoConstruccion />;
        case "VntTipoDocumento":
          return <VntTipoDocumento />;
        case "VntTipoInmueble":
          return <VntTipoInmueble />;/*
        case "VntTipoPromo":
          return <VntTipoPromo />;*/
        case "VntTipoProyecto":
        return <VntTipoProyecto />;
        case "VntUbicaciones":
          return <VntUbicaciones />;
        case "VntVendedores":
          return <VntVendedores />;
        case "CjbCajasCuentas":
          return <CjbCajasCuentas />;
        case "CjbConceptos":
          return <CjbConceptos />;
        case "CjbGestoresCartera":
          return <CjbGestoresCartera />;
        case "TrmBancoHipotecario":
          return <TrmBancoHipotecario />;
        case "TrmBancoInterino":
          return <TrmBancoInterino />;
        case "TrmMotivosRetiro":
          return <TrmMotivosRetiro />;/*
        case "TrmAdenda":
          return <TrmAdenda />;*/
        case "TrmTramitadores":
          return <TrmTramitadores />;

           default:
        return <h2>No hay maestro definido</h2>;
    }
  }

  return (
    <AALLayout>
      <TitlePage title="ABM Maestros" />
      {loadComponent(currentComponent)}
    </AALLayout>
  );
}
