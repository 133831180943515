import SRV from "./Service";
import POS from "./PosService";
import FRM from "./FrmService";
import CRM from "./CrmService";
import { showNotification } from "./ShowNotification";

export const reloadData = async (userUid) => {
  try {
    let datos = await SRV.getUser(userUid);
    console.log(datos);
    if (datos.type > 0) {
      return datos;
    } else {
      showNotification(
        "topRight",
        2,
        "FALLO EN LA CONEXION",
        "Intente mas Tarde"
      );
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

//Descargar datos para el POS
export const downloadDataPos = async (user, turno) => {
  try {
    let datos = await POS.getEnviroment(user, turno);
    console.log(datos);
    if (datos.type > 0) {
      return datos;
    } else {
      showNotification("topRight", 2, "Error Inesperado", "Intente mas Tarde");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const uploadDataPos = async (user, packDatos, turno) => {
  try {
    let datos = await POS.sendGestion(user, packDatos, turno);
    console.log(datos);
    if (datos) {
      return datos;
    } else {
      showNotification("topRight", 2, "Error Inesperado", "Intente mas Tarde");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

//Descargar datos FARM
//Descargar datos para el POS
export const downloadDataFrm = async (user) => {
  try {
    let datos = await FRM.getEnviroment(user);
    console.log(datos);
    if (datos.type > 0) {
      return datos;
    } else {
      showNotification("topRight", 2, "Error Inesperado", "Intente mas Tarde");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
//Enviar Backups
export const uploadBackUpPos = async (user, packDatos, turno) => {
  try {
    let datos = await POS.sendBackups(user, packDatos, turno);
    console.log(datos);
    if (datos) {
      return datos;
    } else {
      showNotification("topRight", 2, "Error Inesperado", "Intente mas Tarde");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

//Descargar datos para el CRM
export const downloadDataCrm = async (user) => {
  try {
    let datos = await CRM.getEnviroment(user);
    console.log(datos);
    if (datos.type > 0) {
      return datos;
    } else {
      showNotification("topRight", 2, "Error Inesperado", "Intente mas Tarde");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
