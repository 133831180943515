import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch, Select, Row, Col } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import CRM from "../../../../../utils/CrmService";

export default function VntVendedoresForm(props) {
  const { record, closeModal, currentUser, paises, supervisores, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.vnd_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [supervisor, setSupervisor] = useState(record.usr_id);
  const [comparteCliente, setComparteCliente] = useState(record.vnd_id > 0 ? record.vnd_comparte_clt : 0);
  const [activo, setActivo] = useState(record.vnd_id > 0 ? record.vnd_activo : 0);
  const [pais, setPais] = useState(record.vnd_id > 0 ? record.pai_id : 0);

  const { Option } = Select;

  useEffect(() => {
    setCurId(record.vnd_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await CRM.sendVntVendedores(
      currentUser,
      curId,
      comparteCliente,
      activo,
      "VntVendedores",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE AGENTES VENDEDORES",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE AGENTES VENDEDORES",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="VntVendedores"
      onFinish={guardarCambios}
      initialValues={{
        vnd_codigo: record.vnd_id > 0 ? record.vnd_codigo : "",
        vnd_nombre: record.vnd_id > 0 ? record.vnd_nombre : "",
        pai_id: record.vnd_id > 0 ? record.pai_id : "",
        vnd_empresa: record.vnd_id > 0 ? record.vnd_empresa : "",
        vnd_direccion: record.vnd_id > 0 ? record.vnd_direccion : "",
        vnd_telefono: record.vnd_id > 0 ? record.vnd_telefono : "",
        vnd_comision: record.vnd_id > 0 ? record.vnd_comision : "",
        usr_id: record.vnd_id > 0 ? record.usr_id : "",
        vnd_precio: record.vnd_id > 0 ? record.vnd_precio : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.vnd_id > 0
            ? "ACTUALIZAR AGENTES VENDEDORES"
            : "AGREGAR AGENTES VENDEDORES"}
        </Title>
      </Form.Item>
      <Form.Item
        name="vnd_codigo"
        label="Codigo"
        rules={[
          {
            required: true,
            message: "El codigo es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase", width: "100px" }}
          value={record.vnd_id > 0 ? record.vnd_codigo : ""}
        />
      </Form.Item>
      <Form.Item
        name="vnd_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.vnd_id > 0 ? record.vnd_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="pai_id"
        label="Pais"
        wrapperCol={{ span: 100 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
          <Select
            showSearch
            showArrow
            style={{ width: "250px" }}
            defaultValue={pais}
            onChange={(val) => setPais(val)}
            placeholder="Paises"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {paises.map((paises) => (
              <Option key={paises.pai_id} value={paises.pai_id}>
                {paises.pai_nombre}
              </Option>
            ))}
          </Select>
      </Form.Item>
      <Form.Item
        name="vnd_empresa"
        label="Empresa"
        rules={[
          {
            required: true,
            message: "La empresa es obligatoria",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.vnd_id > 0 ? record.vnd_empresa : ""}
        />
      </Form.Item>
      <Form.Item
        name="vnd_direccion"
        label="Direccion"
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.vnd_id > 0 ? record.vnd_direccion : ""}
        />
      </Form.Item>
      <Form.Item
        name="vnd_telefono"
        label="Telefono"
        rules={[
          {
            required: true,
            message: "El telefono es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase", width: "170px" }}
          value={record.vnd_id > 0 ? record.vnd_telefono : ""}
        />
      </Form.Item>
      <Form.Item
        name="vnd_comision"
        label="% comision"
      >
        <Input
          style={{ textTransform: "uppercase", width: "170px" }}
          value={record.vnd_id > 0 ? record.vnd_comision : ""}
        />
      </Form.Item>
      <Form.Item
        name="usr_id"
        label="Supervisor"
        wrapperCol={{ span: 100 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
          <Select
            showSearch
            showArrow
            style={{ width: "200px" }}
            defaultValue={supervisor}
            onChange={(val) => setSupervisor(val)}
            placeholder="Supervisor"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {supervisores.map((supervisor) => (
              <Option key={supervisor.id} value={supervisor.id}>
                {supervisor.nombre}
              </Option>
            ))}
          </Select>
      </Form.Item>
      <Form.Item
        name="vnd_precio"
        label="Ver Precio"
        wrapperCol={{ span: 100 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
      <Select 
        defaultValue={record.vnd_id > 0 ? record.vnd_precio : ""}
        placeholder="Precio"
        style={{ width: 150 }}>
        <Option value={1}>TODOS</Option>
        <Option value={2}>PRECIO LOCAL</Option>
        <Option value={3}>EXTRANJERO</Option>
        <Option value={3}>EUROS</Option>
      </Select>
      </Form.Item>
      <Form.Item
        name="vnd_activo"
        label="Activo:"
        wrapperCol={{ span: 15}}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={43}>
          <Switch
            checked={activo}
            onChange={(val) => setActivo(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        Comparte Cliente:
        <Switch
            checked={comparteCliente}
            onChange={(val) => setComparteCliente(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />          
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
