import React, { useState, useEffect } from "react";
import {
  Form,
  Typography,
  Input,
  Space,
  Button,
  Select,
  InputNumber,
} from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

export default function CamposForm(props) {
  const {
    record,
    proveedores,
    tiposCampo,
    closeModal,
    currentUser,
    varEffec,
  } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.id);
  const [idTipo, setIdTipo] = useState(record.cmp_tipo);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setCurId(record.id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const { Title } = Typography;
  const { Option } = Select;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendMaestro(
      currentUser,
      curId,
      "Campos",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification("topRight", 4, "GESTION DE CAMPOS", "REGISTRO EXITOSO");
    } else {
      showNotification("topRight", 2, "GESTION DE CAMPOS", val_record.message);
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="campos"
      onFinish={guardarCambios}
      initialValues={{
        cmp_codigo: record.id > 0 ? record.cmp_codigo : "",
        cmp_nombre: record.id > 0 ? record.cmp_nombre : "",
        cmp_ubicacion: record.id > 0 ? record.cmp_ubicacion : "",
        cmp_tipo: record.id > 0 ? record.cmp_tipo : "",
        trc_id: record.id > 0 ? record.trc_id : "",
        cmp_area: record.id > 0 ? record.cmp_area : 0,
        cmp_alquiler: record.id > 0 ? record.cmp_alquiler : 0,
        cmp_dias: record.id > 0 ? record.cmp_dias : 0,
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.id > 0 ? "ACTUALIZAR CAMPO" : "AGREGAR CAMPO"}
        </Title>
      </Form.Item>
      <Form.Item
        name="cmp_codigo"
        label="Cod. SNIG"
        rules={[
          {
            required: true,
            message: "Codigo SNIG es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase", width: 150 }}
          value={record.id > 0 ? record.cmp_codigo : ""}
          maxLength={20}
        />
      </Form.Item>
      <Form.Item
        name="cmp_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.id > 0 ? record.cmp_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="cmp_ubicacion"
        label="Ubicación"
        rules={[
          {
            required: true,
            message: "La ubicación es obligatoria",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.id > 0 ? record.cmp_ubicacion : ""}
        />
      </Form.Item>
      <Form.Item
        name="cmp_tipo"
        label="Tipo"
        rules={[
          {
            required: true,
            message: "El tipo es obligatorio",
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          style={{ width: "100%" }}
          defaultValue={record.cmp_tipo}
          placeholder="Tipos de Campo"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {tiposCampo.map((tiposc) => (
            <Option key={tiposc.id} value={tiposc.id}>
              {tiposc.tcp_nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="trc_id"
        label="Proveedor"
        rules={[
          {
            required: true,
            message: "El proveedor es obligatorio",
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          style={{ width: "100%" }}
          defaultValue={record.trc_id}
          placeholder="Proveedores"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {proveedores.map((provee) => (
            <Option key={provee.trc_id} value={provee.trc_id}>
              {provee.trc_nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="cmp_area"
        label="Hectareas"
        rules={[
          {
            required: true,
            message: "El area es obligatoria",
          },
        ]}
      >
        <InputNumber
          style={{ width: 150, textAlign: "right" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          defaultValue={record.id > 0 ? record.cmp_area : 0}
          value={record.id > 0 ? record.cmp_area : 0}
        />
      </Form.Item>
      <Form.Item
        name="cmp_alquiler"
        label="Alquiler Mes"
        rules={[
          {
            required: true,
            message: "El Alquiler es obligatorio",
          },
        ]}
      >
        <InputNumber
          style={{ width: 150, textAlign: "right" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          defaultValue={record.id > 0 ? record.cmp_alquiler : 0}
          value={record.id > 0 ? record.cmp_alquiler : 0}
        />
      </Form.Item>
      <Form.Item
        name="cmp_dias"
        label="Dias de Uso"
        rules={[
          {
            required: true,
            message: "Los dias son obligatorios",
          },
        ]}
      >
        <InputNumber
          style={{ width: 100, textAlign: "right" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          defaultValue={record.id > 0 ? record.cmp_dias : 0}
          value={record.id > 0 ? record.cmp_dias : 0}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
