import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import CRM from "../../../../../utils/CrmService";

export default function TrmBancoInterinoForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.bin_id);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setCurId(record.bin_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await CRM.sendMaestro(
      currentUser,
      curId,
      "TrmBancoInterino",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE BANCOS INTERINOS",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE BANCOS INTERINOS",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="TrmBancoInterino"
      onFinish={guardarCambios}
      initialValues={{
        bin_nombre: record.bin_id > 0 ? record.bin_nombre : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.bin_id > 0
            ? "ACTUALIZAR BANCOS INTERINOS"
            : "AGREGAR BANCOS INTERINOS"}
        </Title>
      </Form.Item>
      <Form.Item
        name="bin_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.bin_id > 0 ? record.bin_nombre : ""}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
