import { showNotification } from "./ShowNotification";
const URL_USER = "https://api.aal-erp.com/vbr/api";

class ERP {
  //Datos de entorno
  getEnviroment(user) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Datos del Inventario
  getInventario(user) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/3`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Datos de Proveedores y Clientes
  getTerceros(user, tipo) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("tipo", tipo);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/4`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros
  sendMaestro(user, id, maestro, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

    // Envio de Maestros Compradores
    sendCompradores(user, id, activo, maestro, record) {
        console.log(record);
        let uploadData = new FormData();
        uploadData.append("dbName", user.dbname);
        uploadData.append("id_mobile", user.id_mobile);
        uploadData.append("wservice", user.wservice);
        uploadData.append("id", id);
        uploadData.append("activo", activo);
        uploadData.append("maestro", maestro);
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_USER}/2`, {
          method: "POST",
          body: uploadData,
          headers,
        })
          .then((res) => res.json())
          .catch((error) =>
            showNotification("topRight", 2, "Error Inesperado", error)
          )
          .then((response) => response);
      }

    // Envio de Maestros Compradores
    sendInvSubgruposArt(user, id, grupo, maestro, record) {
        console.log(record);
        let uploadData = new FormData();
        uploadData.append("dbName", user.dbname);
        uploadData.append("id_mobile", user.id_mobile);
        uploadData.append("wservice", user.wservice);
        uploadData.append("id", id);
        uploadData.append("grupo", grupo);
        uploadData.append("maestro", maestro);
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_USER}/2`, {
          method: "POST",
          body: uploadData,
          headers,
        })
          .then((res) => res.json())
          .catch((error) =>
            showNotification("topRight", 2, "Error Inesperado", error)
          )
          .then((response) => response);
      }

    // Envio de Maestros Cajas Cuentas
    sendCjbCajasCuentas(user, id, cuemoneda, cueagrupa, cuetipo, maestro, record) {
      console.log(record);
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("cuemoneda", cuemoneda);
      uploadData.append("cueagrupa", cueagrupa);
      uploadData.append("cuetipo", cuetipo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Cajas Conceptos
    sendCjbConceptos(user, id, ctaid, cnctipo, cncclase, maestro, record) {
      console.log(record);
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("ctaid", ctaid);
      uploadData.append("cnctipo", cnctipo);
      uploadData.append("cncclase", cncclase);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Proveedores Ciudades
    sendPrvCiudades(user, id, region, maestro, record) {
      console.log(record);
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("region", region);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Proveedores Ciudades
    sendPrvConceptosNotas(user, id, activo, cnctipo, maestro, record) {
      console.log(record);
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("activo", activo);
      uploadData.append("cnctipo", cnctipo);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Comercial Ciudades
    sendVntCiudades(user, id, region, maestro, record) {
      console.log(record);
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("region", region);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }

    // Envio de Maestros Formas de Pago
    sendVntFormasPago(user, id, control, fecha, maestro, record) {
      console.log(record);
      let uploadData = new FormData();
      uploadData.append("dbName", user.dbname);
      uploadData.append("id_mobile", user.id_mobile);
      uploadData.append("wservice", user.wservice);
      uploadData.append("id", id);
      uploadData.append("control", control);
      uploadData.append("fecha", fecha);
      uploadData.append("maestro", maestro);
      uploadData.append("record", JSON.stringify(record));
      const headers = new Headers();
      headers.append("accept", "application/json");
      return fetch(`${URL_USER}/2`, {
        method: "POST",
        body: uploadData,
        headers,
      })
        .then((res) => res.json())
        .catch((error) =>
          showNotification("topRight", 2, "Error Inesperado", error)
        )
        .then((response) => response);
    }
}

export default new ERP();
