import React, { useState } from "react";
import {
  Modal,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import "./UploadGanado.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function UploadGanado(props) {
  const { currentUser, closeModal, setIsLoading, myNumber } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idProveedor, setIdProveedor] = useState("SELECCIONE EL PROVEEDOR");
  const [idCampo, setIdCampo] = useState("SELECCIONE EL DESTINO");
  const [area, setArea] = useState("");
  const [areaCtrl, setAreaCtrl] = useState(0);
  const [origen, setOrigen] = useState("");
  const [destare, setDestare] = useState(0);
  const [precioKg, setPrecioKg] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [data2, setData2] = useState("");
  const [listaTxt, setListaTxt] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [snigDif, setSnigDif] = useState([]);
  const [bastonDif, setBastonDif] = useState([]);
  const [downSnig, setDownSnig] = useState(false);
  const [downBaston, setDownBaston] = useState(false);
  const [tipoExcel, setTipoExcel] = useState(1);

  const { Option } = Select;

  //Para uso de excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.ganaderos
  );
  const fieldsList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.campos
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }
  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza el nombre
  const updateCampo = async (value) => {
    setIdCampo(value);
    getAreaFld(fieldsList, value);
  };

  const onChangeArea = (value) => {
    if (Number.parseFloat(value) >= 0) {
      if (Number.parseFloat(value) <= Number.parseFloat(areaCtrl)) {
        setArea(value);
      } else {
        message.error("AREA INCORRECTA", 1);
        return false;
      }
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  //Funcion para traer el area del campo destino
  const getAreaFld = async (listaFld, idFld) => {
    console.log(listaFld);
    listaFld.forEach((arrayValue) => {
      if (Number.parseInt(arrayValue.id) === Number.parseInt(idFld)) {
        setAreaCtrl(Number.parseFloat(arrayValue.cmp_area));
        setArea(Number.parseFloat(arrayValue.cmp_area));
      }
    });
  };

  //Se actualiza el nombre
  const onChangeOrigen = (e) => {
    let orgName = e.target.value;
    setOrigen(orgName.toUpperCase());
  };

  const onChangeDestare = (value) => {
    if (Number.parseInt(value) >= 0) {
      setDestare(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangePrecio = (value) => {
    if (Number.parseInt(value) >= 0) {
      setPrecioKg(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const propis2 = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList2 = fileList2.slice();
      newFileList2.splice(index, 1);
      setFileList2(newFileList2);
    },
    beforeUpload: (file) => {
      if (fileList2.length > 0) {
        setFileList2([file]);
      } else {
        setFileList2([...fileList2, file]);
      }
      UploadExcel2(file);
      return false;
    },
    fileList2,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          return item[0] !== "Fecha Lectura";
        });
        const bh_excel2 = [];
        excel.forEach(function (item, index, arr) {
          //dispositivo.trim();
          let cod = item[1].toString();
          let codigo = cod.split(" ");
          let dispositivo = codigo[0].substr(-8, 8); // + codigo[2].trim();
          let key = index;
          let anm_codigo = dispositivo;
          let grp_codigo = "";
          let sbg_codigo = "";
          let anm_sexo = "";
          let ctg_nombre = "";
          let anm_meses = 0;
          let anm_dias = 0;
          let anm_peso = item[2];

          let exceldata = {
            key: key,
            anm_codigo: anm_codigo,
            grp_codigo: grp_codigo,
            sbg_codigo: sbg_codigo,
            anm_sexo: anm_sexo,
            ctg_nombre: ctg_nombre,
            anm_meses: anm_meses,
            anm_dias: anm_dias,
            anm_peso: anm_peso,
          };

          bh_excel2.push(exceldata);
        });
        setData(bh_excel2);
      }
    });
  };

  const UploadExcel2 = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          let titColumn = item[0].toString();
          //.replace(/^\s+|\s+$/gm, "");
          return titColumn.trim() !== "Dispositivo";
        });
        const bh_excel = [];
        excel.forEach(function (item, index, arr) {
          let valColumn = item[0].toString(); //replace(/^\s+|\s+$/gm, "");
          //let peso = traerPeso(valColumn.trim());
          let key = index;
          let anm_codigo = valColumn.trim();
          let grp_codigo = item[1] ? item[1] : "";
          let sbg_codigo = item[2] ? item[2] : "";
          let anm_sexo = item[3];
          let ctg_nombre = "";
          let anm_meses = item[4];
          let anm_dias = item[5];
          let anm_ubica = item[7];
          let anm_peso = 0;

          let exceldata = {
            key: key,
            anm_codigo: anm_codigo,
            grp_codigo: grp_codigo,
            sbg_codigo: sbg_codigo,
            anm_sexo: anm_sexo,
            ctg_nombre: ctg_nombre,
            anm_meses: anm_meses,
            anm_dias: anm_dias,
            anm_ubica: anm_ubica,
            anm_peso: anm_peso,
          };
          bh_excel.push(exceldata);
        });
        setData2(bh_excel);
      }
    });
  };

  const traerPeso = async () => {
    //setIsLoading(true);
    setLoadingButton(true);
    const new_array = [];
    const new_baston = [];
    const new_snig = [];
    data.forEach((row) => {
      data2.forEach((def) => {
        if (
          Number.parseInt(row.anm_codigo) === Number.parseInt(def.anm_codigo)
        ) {
          let newdata = {
            key: def.key,
            anm_codigo: def.anm_codigo,
            grp_codigo: def.grp_codigo,
            sbg_codigo: def.sbg_codigo,
            anm_sexo: def.anm_sexo,
            ctg_nombre: def.ctg_nombre,
            anm_meses: def.anm_meses,
            anm_dias: def.anm_dias,
            anm_ubica: def.anm_ubica,
            anm_peso: row.anm_peso,
          };

          new_array.push(newdata);
        }
      });
    });
    setDataDef(new_array);

    data.forEach((row) => {
      if (entaEnArray(row.anm_codigo, data2) === 0) {
        let newbaston = {
          anm_codigo: row.anm_codigo,
        };
        new_baston.push(newbaston);
      }
    });
    setBastonDif(new_baston);

    data2.forEach((row) => {
      if (entaEnArray(row.anm_codigo, data) === 0) {
        let newsnig = {
          anm_codigo: row.anm_codigo,
        };
        new_snig.push(newsnig);
      }
    });
    setSnigDif(new_snig);
    setLoadingButton(false);
  };

  //Validar la existencia de un animal
  const entaEnArray = (value, array) => {
    let count = 0;
    array.forEach((arrayValue) => {
      if (Number.parseInt(arrayValue.anm_codigo) === Number.parseInt(value)) {
        count += 1;
      }
    });
    return count > 0 ? count : 0;
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const uploadInvetoryWS = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(idProveedor) === 0 ||
      Number.parseInt(idCampo) === 0 ||
      Number.parseInt(area) === 0 ||
      origen.length === 0 ||
      fechaEntr.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CARGAR CARAVANA",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        trc_id: idProveedor,
        cmp_id: idCampo,
        cmb_area: area,
        ent_origen: origen,
        ent_destare: Number.parseFloat(destare),
        ent_costo_kg: Number.parseFloat(precioKg),
        ent_nota: nota,
        ent_fecha: fechaEntr,
        record_def: dataDef,
      };
      var val_inventory = await FRM.sendEntrada(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CARGAR CARAVANA",
          "REGISTRO DE CARAVANA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      width: "8%",
    },
    {
      title: "RAZA",
      dataIndex: "grp_codigo",
      key: "grp_codigo",
      width: "5%",
    },
    {
      title: "CRUCE",
      dataIndex: "sbg_codigo",
      key: "sbg_codigo",
      width: "5%",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      width: "5%",
    },
    {
      title: "CATEGORIA",
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
      width: "12%",
    },
    {
      title: "MESES",
      dataIndex: "anm_meses",
      key: "anm_meses",
      align: "left",
      width: "2%",
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      align: "left",
      width: "2%",
    },
    {
      title: "UBICACION SNIG",
      dataIndex: "anm_ubica",
      key: "anm_ubica",
      width: "5%",
      align: "left",
    },
    {
      title: "PESO",
      dataIndex: "anm_peso",
      key: "anm_peso",
      width: "2%",
      align: "right",
    },
  ];

  const downDifs = (tipo, arrDif) => {
    setTipoExcel(tipo);

    if (arrDif.length > 0) {
      setDownSnig(true);
      setTimeout(function () {
        setDownSnig(false);
      }, 1000);
    } else {
      message.error("NO HAY DATOS PARA EXPORTAR", 1);
    }
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"CARGAR CARAVANA - ( COINCIDENCIAS: " +
          myNumber(1, dataDef.length) +
          " )"}
      </Title>
      <Row gutter={16}>
        <Col span={3}>
          <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
        </Col>
        <Col span={6}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idProveedor}
            placeholder="PROVEEDOR"
            onChange={updateProveedor}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {suppliersList.map((proveedor) => (
              <Option key={proveedor.trc_id} value={proveedor.trc_id}>
                {proveedor.trc_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Input
            placeholder="Origen Caravana"
            defaultValue={""}
            style={{ width: "100%" }}
            value={origen}
            onChange={onChangeOrigen}
          />
        </Col>
        <Col span={6}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idCampo}
            placeholder="CAMPO DESTINO"
            onChange={updateCampo}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fieldsList.map((campo) => (
              <Option key={campo.id} value={campo.id}>
                {campo.cmp_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={area}
            value={area}
            onChange={onChangeArea}
            placeholder="Hectareas"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={2}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          display: "flex",
          alignContent: "left",
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <Upload {...propis2}>
          <Button
            type="primary"
            shape="round"
            className={curStyle.btnBlueLight}
          >
            <UploadOutlined /> ARCHIVO DEL SNIG
          </Button>
        </Upload>

        <Upload {...propis}>
          <Button
            type="primary"
            shape="round"
            className={curStyle.btnInfo}
            style={{
              marginLeft: 20,
              display: fileList2.length > 0 ? "block" : "none",
            }}
          >
            <UploadOutlined /> ARCHIVO DEL BASTON
          </Button>
        </Upload>
        <Button
          type="primary"
          shape="round"
          className={curStyle.btnOrange}
          onClick={() => traerPeso()}
          loading={loadingButton}
          style={{
            marginLeft: 40,
            display: fileList.length > 0 ? "block" : "none",
          }}
        >
          <UploadOutlined /> VINCULAR ARCHIVOS
        </Button>
      </div>
      <Table
        columns={header}
        dataSource={dataDef}
        bordered
        size="middle"
        scroll={{ x: "calc(700px + 50%)", y: 640 }}
      />
      <Space>
        <Title
          level={4}
          style={{ color: colorSec, textAlign: "left", marginTop: 15 }}
        >
          {"Registros SNIG: " + myNumber(1, data2.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorPrm,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"Registros BASTON: " + myNumber(1, data.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: "purple",
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
            cursor: "pointer",
          }}
          onClick={() => downDifs(1, snigDif)}
        >
          {"En SNIG no en Bastón: " + myNumber(1, snigDif.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: "red",
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
            cursor: "pointer",
          }}
          onClick={() => downDifs(2, bastonDif)}
        >
          {"En Bastón no en SNIG: " + myNumber(1, bastonDif.length)}
        </Title>
      </Space>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadInvetoryWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: dataDef.length > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR LA CARGA DE CARAVANA
          </Button>
        </Popconfirm>
      </div>
      {downSnig ? (
        <ExcelFile
          filename={tipoExcel === 1 ? "Snig_no_baston" : "Baston_no_snig"}
          hideElement={true}
        >
          <ExcelSheet
            data={tipoExcel === 1 ? snigDif : bastonDif}
            name={tipoExcel === 1 ? "SNIG - DIF" : "BASTON - DIF"}
          >
            <ExcelColumn label="DISPOSITIVO" value="anm_codigo" />
          </ExcelSheet>
        </ExcelFile>
      ) : null}
    </div>
  );
}

//https://pastebin.com/yKpF4A2h

function info(text) {
  Modal.info({
    title: "OBSERVACIONES",
    content: (
      <div>
        <p>{text}</p>
      </div>
    ),
    onOk() {},
  });
}
