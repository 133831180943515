import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../../../actions/rolEnviromentActions";

// Picker Color
import ColorPicker from "rc-color-picker";

//Styles
import curStyle from "../../../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../../../utils/CustomSettings";
import { showNotification } from "../../../../../utils/ShowNotification";
import CRM from "../../../../../utils/CrmService";

export default function VntEstadoInmuebleForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.stp_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [colorFondo, setColorFondo] = useState(record.stp_color);
  const [bloquea, setBloquea] = useState(record.stp_id > 0 ? record.stp_bloquea : 0);
  const [factura, setFactura] = useState(record.stp_id > 0 ? record.stp_factura : 0);
  const [precio, setPrecio] = useState(record.stp_id > 0 ? record.stp_precio : 0);
  const [comision, setComision] = useState(record.stp_id > 0 ? record.stp_comision : 0);

  useEffect(() => {
    setCurId(record.stp_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await CRM.sendVntEstadoInmueble(
      currentUser,
      curId,
      bloquea,
      factura,
      precio,
      comision,
      colorFondo,
      "VntEstadoInmueble",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE ESTADOS DE INMUEBLES",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE ESTADOS DE INMUEBLES",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="VntEstadoInmueble"
      onFinish={guardarCambios}
      initialValues={{
        stp_nombre: record.stp_id > 0 ? record.stp_nombre : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.stp_id > 0
            ? "ACTUALIZAR ESTADOS DE INMUEBLES"
            : "AGREGAR ESTADOS DE INMUEBLES"}
        </Title>
      </Form.Item>
      <Form.Item
        name="stp_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.stp_id > 0 ? record.stp_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="stp_bloquea"
        label="Bloquea"
        wrapperCol={{ span: 20 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={20}>
        <Switch
            checked={bloquea}
            onChange={(val) => setBloquea(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
          
          Afecta Facturacióon:
          <Switch
            checked={factura}
            onChange={(val) => setFactura(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
          
        </Space>
      </Form.Item>
      <Form.Item
        name="stp_precio"
        label=" "
        wrapperCol={{ span: 30 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={10}>

          Aplicacion Automatica de Cambio de Precio:
          <Switch
            checked={precio}
            onChange={(val) => setPrecio(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item
        name="stp_precio"
        label=" "
        wrapperCol={{ span: 30 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal" size={10}>

          Aplica para el Cobro o Pago de Comisiones:
          <Switch
            checked={comision}
            onChange={(val) => setComision(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item
        name="stp_color"
        label="Color"
        wrapperCol={{ span: 10 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal">
          <Input value={colorFondo} style={{ width: 100 }} />
          <ColorPicker
            animation="slide-up"
            color={record.stp_id > 0 ? record.stp_color : ""}
            onChange={(color) => setColorFondo(color.color)}
          />
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
